<script setup lang="ts"></script>

<template>
  <div class="w-full h-full">
    <slot />
  </div>
</template>

<style lang="postcss">
@import "@/assets/css/main.css";
</style>
